import { datatypes } from "@/components/DataTableGenerico/datatypes";
import { ClassCombo } from "./ClassCombo";
import { ComponentsTypes } from "./TipoComponentes";
import { ClassRadioButtonSource } from "./ClassRadioButtonSource";
import { UtilsString } from "@/utils/utils-string";
import { ClassCodigoDescripcionSelector } from "./ClassCodigoDescripcionSelector";

export class FormItem {
  public caption!: string;
  public datatype!: string;
  public data!: any;
  public vbind: any={};
  public AllDataChanged: any[] = [];
  public visible!: boolean;
  public Required: boolean = false;
  public maxLength!: number;
  public Combo: ClassCombo = new ClassCombo();
  public name: string = "";
  public validacion: string = "";
  public icon: string = "";
  public variableName: string = "";
  public validaciones: string[] = [];
  public readonly: boolean = false;
  public readonly_solo_seleccion_fecha: boolean = false;
  public mayuscula: boolean = false;
  public dateFormatted: string | null = "";
  public OpenPopupMenu: boolean = false;
  public componenttype: ComponentsTypes = ComponentsTypes.TextBox;
  public index: number = -1;
  public SwitchOn: string = "";
  public SwitchOff: string = "";
  public UseCaptionForSwitch: boolean = true;
  public DataSourceRadioButton: ClassRadioButtonSource[] = [];
  public intab: number = 1;
  public dim_cols: number = 12;
  public dim_sm: number = 6;
  public dim_md: number = 4;
  public minDate: string = "";
  //para guardar datos que se necesiten
  public metadatasave: any = undefined;

  public isCodigo: boolean = false;
  public Codigo_Descripcion_selector!: ClassCodigoDescripcionSelector;

  constructor(
    data: any,
    caption: string,
    variableName: string,
    datatype: string,
    visible: boolean,
    maxLength: number,
    intab: number = 1
  ) {
    if (variableName !== "" && caption !== "") {
      this.variableName = variableName;
      this.caption = caption; //.toLowerCase();
      this.name = this.caption; //.toLowerCase();
    } else {
      //pues aqui será un slot o solo habilitar 1 campo para 1 tab
      this.variableName = UtilsString.GenerateGuid();
      this.caption = "";
      this.name = this.variableName.toLowerCase();
    }
    //this.datafield = datafield;

    this.datatype = datatype;
    this.visible = visible;
    this.maxLength = maxLength;
    this.intab = intab;
    this.data = data;
    switch (datatype) {
      case datatypes.number:
        this.CrearValidacion("numeric");
        break;
    }
  }
  public get Olddata(): any {
    if (this.AllDataChanged.length === 0) {
      return undefined;
    }
    return this.AllDataChanged[this.AllDataChanged.length - 1];
  }

  public isRequired(Required: boolean = true) {
    this.Required = Required;
    if (Required && !this.caption.includes(" *")) {
      this.caption += " *";
    }
    if (!Required) {
      this.caption = this.caption.replace(" *", "").trim();
    }
    return this;
  }
  public isMayuscula(mayuscula: boolean = true) {
    this.mayuscula = mayuscula;
    return this;
  }
  public isReadOnly(ReadOnly: boolean = true) {
    this.readonly = ReadOnly;
    this.readonly_solo_seleccion_fecha =ReadOnly;
    return this;
  }
  public isReadOnlySoloSeleccionFecha(ReadOnly: boolean = true) {
    this.readonly = ReadOnly;
    this.readonly_solo_seleccion_fecha = !ReadOnly;
    return this;
  }
  
  public isVisible(Visible: boolean = false) {
    this.visible = Visible;
    return this;
  }

  public thisIsCodigo(codigo: boolean = true) {
    this.isCodigo = codigo;
    return this;
  }
  private TipoComponente(componente: ComponentsTypes) {
    this.componenttype = componente;
    switch (componente) {
      case ComponentsTypes.TablaCodigoDescripcion:
        if (this.data === 0) {
          /*En modo edición, el Back devuelve la instancia del objeto con algunos de los datos de desplegables a 0 con lo que al Actualizar, este dice, que no encontro en la base de datos dicho registro para solucionar este problema, el front propone esta linea de codigo*/
          this.data = null;
        }
        break;
    }
    return this;
  }

  public CreateSwitch(Texton: string, Textoff: string) {
    this.SwitchOff = Textoff.toLowerCase().toString();
    this.SwitchOn = Texton.toLowerCase().toString();
    this.TipoComponente(ComponentsTypes.Switch);
    this.UseCaptionForSwitch = false;
    return this;
  }
  public CreateTextArea() {
    this.TipoComponente(ComponentsTypes.TextArea);
    this.Dimesiones(12, 12, 12);
    return this;
  }

  public CreateDateTime() {
    this.TipoComponente(ComponentsTypes.DateTime);
    this.CrearValidacion("date_format:DD/MM/YYYY");
    return this;
  }

  public CreateRadioButton(DataSourceRadioButton: ClassRadioButtonSource[]) {
    this.TipoComponente(ComponentsTypes.RadioButton);
    this.DataSourceRadioButton = DataSourceRadioButton;
    return this;
  }

  public CreateComboSingle(
    DataSource: any[],
    DisplayExpr: string,
    ValueExpr: string = "id"
  ) {
    this.Combo.AllDataSource = DataSource;
    this.Combo.DisplayExpr = DisplayExpr;
    this.Combo.ValueExpr = ValueExpr;
    this.Combo.DataSource = [];
    this.TipoComponente(ComponentsTypes.SingleCombo);
    for (let i = 0; i < this.Combo.AllDataSource.length; i++) {
      let value = DataSource[i][ValueExpr];
      let text = DataSource[i][DisplayExpr];
      this.Combo.DataSource.push({ text: text, value: value });
    }
    return this;
  }

  public CreateComboSingleCodigoDescripcion(
    DataSource: any[],
    PreFijoExpresion: string,
    ValueExpr: string = "id",
    MoreColumns: string[] = [],
    Widht: number = 500
  ) {
    this.Codigo_Descripcion_selector = new ClassCodigoDescripcionSelector();
    this.Codigo_Descripcion_selector.AllDataSource = DataSource;
    this.Codigo_Descripcion_selector.CodigoNameColumn =
      PreFijoExpresion + "Codigo";
    this.Codigo_Descripcion_selector.DescripcionNameColumn =
      PreFijoExpresion + "Descripcion";
    this.Codigo_Descripcion_selector.ValueNameColumn = ValueExpr;
    this.Codigo_Descripcion_selector.MoreColumns = MoreColumns;
    this.Codigo_Descripcion_selector.WidhtCodigoDescripcion = Widht;
    this.TipoComponente(ComponentsTypes.TablaCodigoDescripcion);
    return this;
  }
  public CreateComboSingleCodigoDescripcionNames(
    DataSource: any[],
    DisplayExprCodigo: string,
    DisplayExprDescripcion: string,
    ValueExpr: string = "id",
    MoreColumns: string[] = [],
    Widht: number = 500
  ) {
    //este es com nombres para las que no tiene codigo y descripcion fijos

    this.Codigo_Descripcion_selector = new ClassCodigoDescripcionSelector();
    this.Codigo_Descripcion_selector.AllDataSource = DataSource;
    this.Codigo_Descripcion_selector.CodigoNameColumn = DisplayExprCodigo;
    this.Codigo_Descripcion_selector.DescripcionNameColumn = DisplayExprDescripcion;
    this.Codigo_Descripcion_selector.ValueNameColumn = ValueExpr;
    this.Codigo_Descripcion_selector.MoreColumns = MoreColumns;
    this.Codigo_Descripcion_selector.WidhtCodigoDescripcion = Widht;
    this.TipoComponente(ComponentsTypes.TablaCodigoDescripcion);
    return this;
  }

  public CreateComboMultiple(
    DataSource: any[],
    DisplayExpr: string,
    ValueExpr: string = "id"
  ) {
    this.Combo.AllDataSource = DataSource;
    this.Combo.DataSource = [];
    this.Combo.DisplayExpr = DisplayExpr;
    this.Combo.ValueExpr = ValueExpr;
    this.TipoComponente(ComponentsTypes.MultiCombo);
    for (let i = 0; i < this.Combo.AllDataSource.length; i++) {
      let value = DataSource[i][ValueExpr];
      let text =
        DataSource[i][DisplayExpr + "Codigo"] +
        " - " +
        DataSource[i][DisplayExpr + "Descripcion"];
      this.Combo.DataSource.push({ text: text, value: value });
    }
    return this;
  }

  public CreateComboMultipleNames(
    DataSource: any[],
    DisplayExprCodigo: string,
    DisplayExprDescripcion: string,
    ValueExpr: string = "id"
  ) {
    this.Combo.AllDataSource = DataSource;
    this.Combo.DataSource = [];
    this.Combo.DisplayExpr = DisplayExprCodigo;
    this.Combo.ValueExpr = ValueExpr;
    this.TipoComponente(ComponentsTypes.MultiCombo);
    for (let i = 0; i < this.Combo.AllDataSource.length; i++) {
      let value = DataSource[i][ValueExpr];
      let text =
        DataSource[i][DisplayExprCodigo] ;
      this.Combo.DataSource.push({ text: text, value: value });
    }
    return this;
  }

  public CreateInNewROW() {
    this.dim_cols = 12;
    this.dim_sm = 12;
    this.dim_md = 12;
    return this;
  }

  public CrearIcono(icono: string) {
    this.icon = icono;
    return this;
  }
  public CrearValidacion(validacion: string) {
    //si hay una validacion de numeric y se pone una validacion de decimal no funciona la de dencimal
    if (validacion.includes("decimal")) {
      //si recibo que quiero poner una validacion de decimal quito la de number
      let index = this.validaciones.findIndex((x: string) => x === "numeric");
      this.validaciones.splice(index, 1);
      this.validacion = this.validacion.replace("numeric", "");
    }

    if (!this.validaciones.includes(validacion)) {
      this.validaciones.push(validacion);
      for (let i = 0; i < this.validaciones.length; i++) {
        if (!this.validacion.includes(this.validaciones[i])) {
          this.validacion += this.validaciones[i];
        }
        if (i !== this.validaciones.length - 1) {
          this.validacion += "|";
        }
      }
    }
    return this;
  }

  public SetValidacion(validacion: string) {
    this.validaciones = [];
    this.validaciones.push(validacion);
    this.validacion = validacion;
    return this;
  }
  public SetMaxLenght(maxLength: number) {
    this.maxLength = maxLength;
    return this;
  }
  public SetComponent(componenttype: ComponentsTypes) {
    this.TipoComponente(componenttype);
    return this;
  }
  public SetMetadata(metadata: any) {
    this.metadatasave = metadata;
    return this;
  }
  public Dimesiones(cols: number = 12, sm: number = 6, md: number = 4) {
    this.dim_cols = cols;
    this.dim_sm = sm;
    this.dim_md = md;
    return this;
  }

  public SetTabNumber(number: number) {
    this.intab = number;
    return this;
  }
}
