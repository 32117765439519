export enum ComponentsTypes {
  TextBox = 0,
  MultiCombo = 1,
  SingleCombo = 2,
  DateTime = 3,
  TextArea = 4,
  Switch = 5,
  RadioButton = 6,
  TablaCodigoDescripcion = 7,
  Slot = 99
}
