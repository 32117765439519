import { BaseDto } from "@/shared/dtos/base-dto";

export class objetivo extends BaseDto {
  public codigo!: string;
  public nombre!: string;
  public descripcion!: string;
  public objetivo_llegar!: string;
  public estrategias!: number[];
  public kpis_progreso!: number;
  public progreso!: number;
}
